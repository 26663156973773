'use client';

import { RestAPI } from '@aws-amplify/api-rest';
import { pick } from 'ramda';
import { useCallback, useEffect, useMemo } from 'react';

import { pageEvents, PageEventsKeys, trackEvents } from '@squadnet/frontend-shared-analytics';
import { requestIdleCallback } from '@squadnet/frontend-web-ui';
import { USER_EXTERNAL_SERVICES } from '@squadnet/universal-constants';

import configureAmplify from '@/config/amplify';
import { useGuest } from '@/hooks/logic/useGuest';
import posthog from '@/modules/posthog';
import rudderClient from '@/modules/rudderstack';

RestAPI.configure(configureAmplify());

export const useAnalyticsUser = () => {
  const { id: guestId, setGuestId } = useGuest();

  const setGuest = useCallback(
    async (id: string = '') => {
      if (id.includes(':')) {
        await RestAPI.post('customer-support', 'user/guest', {
          body: {
            guestId: id,
          },
        });
        rudderClient.setAnonymousId(id);
        posthog.identify(id);
        setGuestId(id);
      }
    },
    [guestId],
  );

  const setUserId = useCallback(
    async (id: string, opt?: any) => {
      if (opt?.authId) {
        const identityOpts = opt
          ? {
              ...pick(['name'], opt),
            }
          : {};
        await rudderClient.identify(id, identityOpts);
        posthog.identify(id, identityOpts);

        if ((opt?.externals || []).findIndex(x => x?.external === USER_EXTERNAL_SERVICES.CUSTOMERIO) === -1) {
          await RestAPI.post('customer-support', 'user/external', {
            body: {
              userId: id,
            },
          });
        }

        if (guestId) {
          await rudderClient.alias(id, guestId);
          await RestAPI.post('customer-support', 'user/merge', {
            body: {
              guestId,
              id,
            },
          });
          // eslint-disable-next-line
          setGuestId('');
        }
      }
    },
    [guestId],
  );

  return {
    setGuest,
    setUserId,
  };
};

export const page = (name: string, data?: any) => {
  let payload = data || {};
  if (typeof payload !== 'object') {
    payload = {
      payload,
    };
  }
  rudderClient.page(name, payload);
};

export const track = (name: string, data?: any) => {
  let payload = data || {};
  if (typeof payload === 'object') {
    payload = {
      payload,
    };
  }
  rudderClient.track(name, payload);
};

const tracks = trackEvents(track);

export const capture = (name: string, data?: any, element?: any) => {
  rudderClient.track(name, {
    data,
    path: window.location.pathname,
    referrer: window.document.referrer,
    element,
  });
};

export const reset = () => {
  rudderClient.reset();
};

export const useAnalytics = (name?: PageEventsKeys, data?: Record<string, any>) => {
  useEffect(() => {
    if (name) {
      requestIdleCallback(() => {
        page(pageEvents[name]?.event, {
          path: window.location.pathname,
          referrer: window.document.referrer,
          ...(data || {}),
          metrics: pageEvents[name]?.metrics,
        });
      });
    }
  }, []);

  return useMemo(
    () => ({
      ...tracks,
      capture,
      reset,
      track: (name: PageEventsKeys, payload?: any) =>
        track(pageEvents[name]?.event, {
          ...(payload || {}),
        }),
    }),
    [],
  );
};
