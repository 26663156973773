import { configs } from '@/config/shared';

const rudderClient =
  typeof window === 'undefined'
    ? {
        setAnonymousId: () => {},
        alias: () => {},
        identify: () => {},
        track: () => {},
        page: () => {},
      }
    : (() => {
        const rudderanalytics = require('rudder-sdk-js');
        rudderanalytics.ready(() => {
          console.log('we are all set!!!');
        });
        rudderanalytics.load(configs.rudderWriteKey, configs.rudderPlan);
        // Setting windown rudderanalytics to prevent eval error
        (window as any).rudderanalytics = rudderanalytics;
        return rudderanalytics;
      })();

export default rudderClient;
