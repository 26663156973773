import { useAtomValue, useSetAtom } from 'jotai';

import { guestAtom, guestIdAtom } from '@squadnet/frontend-shared-states';

export const useGuest = () => {
  const info = useAtomValue(guestAtom);
  const setGuestId = useSetAtom(guestIdAtom);
  return {
    ...info,
    setGuestId,
  };
};
