import { configs } from '@/config/shared';

export const FLAGS = {
  POST_EVENT: 'post-event',
  SCHEDULED_EVENT_STATUS: 'scheduled-event-status',
  SCHEDULED_EVENT_COMMENT: 'scheduled-event-comment',
};

const posthogClient =
  typeof window === 'undefined'
    ? {
        init: () => {},
      }
    : (() => {
        const posthog = require('posthog-js').default;
        posthog.init(configs.posthogKey, {
          api_host: configs.posthogHost,
          bootstrap: {
            featureFlags: {
              [FLAGS.POST_EVENT]: false,
              [FLAGS.SCHEDULED_EVENT_STATUS]: false,
              [FLAGS.SCHEDULED_EVENT_COMMENT]: false,
            },
          },
        });
        return posthog;
      })();

export default posthogClient;
